export const BOOK_SEARCH_FORM = "BOOK_SEARCH_FORM";
export const BOOK_IMPORT_FORM = "BOOK_IMPORT_FORM";
export const BOOK_EXPORT_FORM = "BOOK_EXPORT_FORM";
export const ADDRESS_BOOK_FORM = "ADDRESS_BOOK_FORM";
export const CREATE_ADDRESS_BOOK_GROUP = "CREATE_ADDRESS_BOOK_GROUP";
export const EDIT_ADDRESS_BOOK_GROUP = "EDIT_ADDRESS_BOOK_GROUP";
export const SEARCH_AND_SELECT_HELP = "SEARCH_AND_SELECT_HELP";
export const VALIDATION_AND_ASSOCIATED_HELP = "VALIDATION_AND_ASSOCIATED_HELP";
export const UPDATE_AND_SAVE_HELP = "UPDATE_AND_SAVE_HELP";

export const ADDRESS = "address";
export const NOTIFICATION_DETAILS = "notificationDetails";
export const CONTACT_DETAILS = "contactDetails";
export const AUDIT_INFO = "auditInfo";

export const Fields = {
  SHORT_NAME: "shortName",
  COUNTRY_CODE: "countryCode",
  POSTCODE: "postcode",
  ORGANISATION: "organisation",
  TOWN: "town",
  COUNTY: "county",
  STREET: "street",
  LOCALITY: "locality",
  CONTACT_NAME: "contactName",
  ADDRESS_TYPE: "addressType",
  CONTACT_TELEPHONE: "telephone",
  NOTIFICATION_MOBILE: "mobile",
  NOTIFICATION_EMAIL: "email",
  SHIPPING_REF_1: "shippingRef1",
  SHIPPING_REF_2: "shippingRef2",
  SHIPPING_REF_3: "shippingRef3",
  DELIVERY_INSTRUCTION: "deliveryInstructions",
  IS_DEFAULT_ADDRESS: "isDefaultAddress",
  IS_VALID: "isValid",
  READ_ONLY: "readOnly",
  VAT_NUMBER: "vatNumber",
  TYPE: "type",
  GROUP_NAME: "groupName",
  GROUP_ID: "groupId",
  NETWORK_CODE: "networkCode",
  IS_DEFAULT: "isDefault",
  EORI_NUMBER: "eoriNumber",
  PID_NUMBER: "pid",
};

export const AddressBookEntity = {
  ADDRESS: {
    COUNTRY_CODE: `${ADDRESS}.${Fields.COUNTRY_CODE}`,
    POSTCODE: `${ADDRESS}.${Fields.POSTCODE}`,
    ORGANISATION: `${ADDRESS}.${Fields.ORGANISATION}`,
    TOWN: `${ADDRESS}.${Fields.TOWN}`,
    COUNTY: `${ADDRESS}.${Fields.COUNTY}`,
    STREET: `${ADDRESS}.${Fields.STREET}`,
    LOCALITY: `${ADDRESS}.${Fields.LOCALITY}`,
  },
  ADDRESS_TYPE: Fields.ADDRESS_TYPE,
  AUDIT_INFO: {
    CREATED_DATE: `${AUDIT_INFO}.createdDate`,
    UPDATED_DATE: `${AUDIT_INFO}.updatedDate`,
    OWNER: `${AUDIT_INFO}.ownerRecord`,
  },
  CONTACT_DETAILS: {
    CONTACT_NAME: `${CONTACT_DETAILS}.${Fields.CONTACT_NAME}`,
    TELEPHONE: `${CONTACT_DETAILS}.${Fields.CONTACT_TELEPHONE}`,
  },
  DELIVERY_INSTRUCTION: Fields.DELIVERY_INSTRUCTION,
  GROUPS: "groups",
  IS_DEFAULT: Fields.IS_DEFAULT,
  IS_VALID: Fields.IS_VALID,
  NOTIFICATION_DETAILS: {
    MOBILE: `${NOTIFICATION_DETAILS}.${Fields.NOTIFICATION_MOBILE}`,
    EMAIL: `${NOTIFICATION_DETAILS}.${Fields.NOTIFICATION_EMAIL}`,
  },
  READ_ONLY: "readOnly",
  SHIPPING_REF_1: Fields.SHIPPING_REF_1,
  SHIPPING_REF_2: Fields.SHIPPING_REF_2,
  SHIPPING_REF_3: Fields.SHIPPING_REF_3,
  SHORT_NAME: Fields.SHORT_NAME,
  VAT_NUMBER: Fields.VAT_NUMBER,
  EORI_NUMBER: Fields.EORI_NUMBER,
  PID_NUMBER: Fields.PID_NUMBER,
};

export const OptionsList = [
  {
    value: AddressBookEntity.SHORT_NAME,
    label: "Short Name",
  },
  {
    value: AddressBookEntity.ADDRESS.COUNTRY_CODE,
    label: "Country",
  },
  {
    value: AddressBookEntity.ADDRESS.POSTCODE,
    label: "Postcode",
  },
  {
    value: AddressBookEntity.ADDRESS.ORGANISATION,
    label: "Organisation",
  },
  {
    value: AddressBookEntity.ADDRESS.STREET,
    label: "Address Line 1",
  },
  {
    value: AddressBookEntity.ADDRESS.LOCALITY,
    label: "Address Line 2",
  },
  {
    value: AddressBookEntity.ADDRESS.TOWN,
    label: "Town",
  },
  {
    value: AddressBookEntity.ADDRESS.COUNTY,
    label: "County",
  },
  {
    value: AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME,
    label: "Contact",
  },
  {
    value: AddressBookEntity.CONTACT_DETAILS.TELEPHONE,
    label: "Telephone",
  },
  {
    value: AddressBookEntity.NOTIFICATION_DETAILS.EMAIL,
    label: "Email",
  },
  {
    value: AddressBookEntity.NOTIFICATION_DETAILS.MOBILE,
    label: "Mobile",
  },
  {
    value: AddressBookEntity.DELIVERY_INSTRUCTION,
    label: "Delivery Instructions",
  },
  {
    value: AddressBookEntity.SHIPPING_REF_1,
    label: "Ref 1",
  },
  {
    value: AddressBookEntity.SHIPPING_REF_2,
    label: "Ref 2",
  },
  {
    value: AddressBookEntity.SHIPPING_REF_3,
    label: "Ref 3",
  },
  {
    value: AddressBookEntity.IS_VALID,
    label: "Valid",
  },
  {
    value: AddressBookEntity.VAT_NUMBER,
    label: "Vat Number",
  },
];

export const AddressBookFilterOptionsList = [
  {
    value: Fields.SHORT_NAME,
    label: "Short Name",
  },
  {
    value: Fields.POSTCODE,
    label: "Postcode",
  },
  {
    value: Fields.ORGANISATION,
    label: "Organisation",
  },
  {
    value: Fields.COUNTRY_CODE,
    label: "Country",
  },
  {
    value: Fields.STREET,
    label: "Address Line 1",
  },
  {
    value: Fields.LOCALITY,
    label: "Address Line 2",
  },
  {
    value: Fields.TOWN,
    label: "Town / City",
  },
  {
    value: Fields.CONTACT_NAME,
    label: "Contact",
  },
];

export const SEARCH_CRITERIA_FIELD = "searchCriteriaField";
export const SEARCH_CRITERIA_VALUE = "searchCriteriaValue";
export const ADDRESS_TYPE = "addressType";
const TEMPLATE = "type";
const ADDRESS_BOOK_FILE = "addressBookFile";
const IMPORT_OPTIONS = "updateType";
const GROUP_IMPORT = "groupImportBehavior";

export const FilterFields = {
  ADDRESS_TYPE,
  SEARCH_CRITERIA_FIELD,
  SEARCH_CRITERIA_VALUE,
  [Fields.TYPE]: Fields.TYPE,
};

export const AddressBookImportFields = {
  TEMPLATE,
  ADDRESS_BOOK_FILE,
  IMPORT_OPTIONS,
  GROUP_IMPORT,
};

export const AddressBookExportFields = {
  TEMPLATE,
};

export const ImportOptionsValue = {
  IMPORT_NEW_ONLY: "0",
  OVERWRITE: "1",
  EMPTY: "2",
};

export const TemplateType = { DEFAULT: "0", RETURN: "1" };

export const GroupImportBehaviorValue = {
  DEFAULT: "0",
  UPDATE: "1",
};

export const TemplateList = [
  {
    value: TemplateType.DEFAULT,
    label: "CUSTOMER ADDRESS BOOK TEMPLATE",
  },
  {
    value: TemplateType.RETURN,
    label: "RETURN ADDRESS BOOK TEMPLATE",
  },
];

export const FIELDS_LENGTH = {
  default: 35,
  postcode: 8,
  shortName: 45,
  telephone: 15,
  email: 100,
  instructions: 50,
  reference: 25,
  vatNumber: 45,
};

export const DEFAULT_FILE_EXTENSIONS = ".csv,.tsv";
